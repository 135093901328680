.image-gallery {
    display: flex;
    justify-content: space-between;
    margin-inline: 8px;
}
.left-menu {
    width: 14%;
    padding-inline: 10px;
    background-color: #f0f0f0;
    position: fixed;
    top: 80px;
    z-index: 1;
    border: 1px solid #ccc;
}

.menu-section {
    margin-bottom: 15px;
}

.filter label {
    display: block;
    margin-bottom: 10px;
}


.image-gallery-content {
    flex-grow: 1;
    margin-left: 16%;
}


.gallery-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    width: 100%;
    overflow: visible;
}

.image-card {
    border: 2px solid #ddd;
    padding: 10px;
    transition: transform 0.2s;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}


.image-card:hover {
    transform: scale(1.02);
}

.image-card-overlay {
    text-align: center; /* Center the text */
}

.image-card img {
    width: 100%;
    max-height: 70%; /* Ensure the image does not overflow the card */
    object-fit: contain;
    cursor: pointer;
}

.image-card p {
    overflow: hidden; /* Ensure text does not overflow the card */
}

.image-card-buttons {
    display: flex;
    justify-content: space-evenly; /* or space-around */
    align-items: center;
    width: 100%;
    margin-top: 6px;
}

.image-card-buttons .button .input {
    flex-grow: 1; /* Make the button grow to fill available space */

}

.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
}

.preview-modal img {
    max-width: 90%;
    max-height: 90%;
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

.delete-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
    text-align: center;
}


.modal-manage-region-buttons {
    padding: 20px;
}

.delete-confirmation p {
    margin-bottom: 20px;
}

.delete-confirmation button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 4px;
    outline: none;
}

.delete-confirmation button:hover {
    background-color: #ff0000;
}

.left-section {
    flex: 1;
    margin-right: 20px;
    text-align: center;
}

.left-section h1,
.left-section h2 {
    text-align: center;
}

.image-container {
    overflow: hidden;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.image-container input[type="text"] {
    width: 80%; /* Make the input field take full width */
    padding: 10px; /* Add padding for better spacing */
    margin: 10px; /* Add margin at the bottom for spacing */
    text-align: center;
}

.image-container button {
    margin: 10px;
}


.upload-button {
    text-align: center;
    margin: 10px; /* Adjust the margin-top as needed */
    font-size: 14px; /* Adjust the font size as needed */

}

.refresh-button button {
    font-size: 14px; /* Adjust the font size as needed */
    padding: 8px 14px; /* Adjust the padding as needed */
}

.image-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-item a {
    text-decoration: none;
    color: #333;
}

.image-item img {
    max-height: 120px;
    max-width: 100%;
}

.image-item p {
    margin-top: 5px;
}
