/* ManageRegion.css */

.modal-manage-region {
    /* Add styles for the modal container */
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content {
    /* Add styles for the modal content */
}

.select-region {
    /* Add styles for the region select */
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.selected-images-container {
    max-height: 200px;
    overflow-y: auto;
}

.selected-images-list {
    /* Add styles for the list of selected images */
    list-style: none;
    padding: 0;
    margin: 0;
}

.selected-images-list li {
    margin-bottom: 8px;
}

.button-primary,
.button-secondary,
.button-close {
    /* Add styles for the buttons */
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 8px;
    cursor: pointer;
}

.button-secondary {
    background-color: #dc3545;
}

.button-close {
    background-color: #6c757d;
}
