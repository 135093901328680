.suggestions-container {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    padding: 0;
}

.suggestion-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.suggestion-item:last-child {
    border-bottom: none;
}

.suggestion-item:hover {
    background-color: #f2f2f2;
}

.suggestion-item p {
    margin: 0; /* Remove default margin */
}
